/*
 * @Description:
 * @Version: 3.0
 * @Autor: LIN
 * @Date: 2023-10-09 18:40:07
 * @LastEditors: LIN
 * @LastEditTime: 2023-10-10 16:20:04
 */
import { getMessageList } from '@/api/notice';
import { EMsgTbas } from '@/enums/notice.ts';
export const useNoticeStore = defineStore('notice', {
  state: () => ({
    noticeList: [],
    loading: false
  }),
  getters: {
    getNoticeList: state => state.noticeList
  },
  actions: {
    setNoticeList(noticeList) {
      this.noticeList = noticeList;
    },
    requestNoticeDataFn(is_read = EMsgTbas.unread, page = 1, pageSize = 10) {
      return new Promise(resolve => {
        this.loading = true;
        const params = {
          page,
          pageSize,
          is_read
        };
        getMessageList(params).then(res => {
          const {
            items,
            total
          } = res.result;
          // console.log('items 通知数据', items)
          if (EMsgTbas.unread === is_read) {
            // 未读数据同步全局
            this.setNoticeList(items.slice(0, 5));
          }
          resolve({
            items,
            total
          });
        }).catch(() => {}).finally(() => {
          this.loading = false;
        });
      });
    }
  }
});